import './bootstrap';
import Headroom from "headroom.js";
import AOS from "aos";

// Declarations
window.onload = function() {
	let body = document.querySelector('body');
	let header = document.querySelector(".page-header");
	let sticky_footer = document.querySelector('.sticky-footer');
	let mobile_menu = document.querySelector('.nav-mobile');
	let mobile_menu_open_light_btn = document.querySelector('.mobile-menu-open-light');
	let mobile_menu_open_dark_btn = document.querySelector('.mobile-menu-open-dark');
	let mobile_menu_close_btn = document.querySelector('.mobile-menu-close');
	let contact_section = document.querySelector('.contact');
	let contact_section_offset_y = contact_section.getBoundingClientRect().top + window.scrollY - window.innerHeight + sticky_footer.getBoundingClientRect().height;
	let nav_links = document.querySelectorAll('.nav-link');
	let mobile_nav_contact_btn = document.querySelector('#mobile-nav-contact-btn');
	let mobile_menu_logo = document.querySelector('.mobile-menu-logo');
	let last_scroll_top = 0;

	AOS.init({
		disable: () => { return window.scrollY > 0 } 
	});

	/* 
	These two if-statements are run once upon page load. They check to make sure
	we aren't loading the page in a scrolled state, if we do, then we need to adjust some stylings
	of the header and sticky footer to accommodate the page being loaded in a scrolled manner.
	*/
	if (window.scrollY > 50) {
		header.classList.add('headroom--unpinned');
	}

	if (window.scrollY > contact_section_offset_y) {
		sticky_footer.style.bottom = '-80px';
		sticky_footer.style.transition = '0.5s ease all'
		setTimeout(() => {
			sticky_footer.style.position = 'static';
		}, 500)
	}

	/* 
	This piece of code delays adding smooth scroll to the root element by 3 seconds.
	This allows a failed/successful form validation to redirect directly to #contact section
	without smooth-scrolling through the website (i.e. it will just jump to the contact section).
	In this instance we don't want the user to experience smooth scrolling since it can be jarring for
	most of the website to scroll by very quickly
	*/
	setTimeout(() => {
		document.querySelector(':root').style.scrollBehavior = 'smooth'
	}, 3000)

	let options = {
		tolerance: 0,
		offset: 0
	}
	let headroom = new Headroom(header, options);
	headroom.init();

	// Event Listeners
	nav_links.forEach(link => {
		link.addEventListener('click', closeMobileMenu);
	})
	mobile_nav_contact_btn.addEventListener('click', closeMobileMenu);
	mobile_menu_open_light_btn.addEventListener('click', openMobileMenu);
	mobile_menu_open_dark_btn.addEventListener('click', openMobileMenu);
	mobile_menu_close_btn.addEventListener('click', closeMobileMenu);
	mobile_menu_logo.addEventListener('click', closeMobileMenu);

    document.addEventListener('scroll', () => {
		if (window.scrollY && window.scrollY > 50) {
			header.classList.add('scrolled-header-style');
			header.classList.remove('unscrolled-header-style');
			header.style.transition = '0.25s ease all';
		} else {
			header.classList.add('unscrolled-header-style');
			header.classList.remove('scrolled-header-style');
		}
	})

	document.addEventListener('scroll', () => {
		if (window.scrollY > 50) {
			mobile_menu_open_light_btn.style.display = 'none'
			mobile_menu_open_dark_btn.style.display = 'block'
		} else if (window.scrollY <= 50) {
			mobile_menu_open_light_btn.style.display = 'block'
			mobile_menu_open_dark_btn.style.display = 'none'
		}
	})

	document.addEventListener('scroll', () => {
		if (window.scrollY > contact_section_offset_y) {
			sticky_footer.style.position = 'static';
		} else {
			sticky_footer.style.position = 'fixed';

			let scrollTop = window.pageYOffset || document.documentElement.scrollTop;

			if (scrollTop > last_scroll_top){
				sticky_footer.classList.add('scrolled-footer')
				sticky_footer.classList.remove('unscrolled-footer')
			} else {
				sticky_footer.classList.remove('scrolled-footer')
				sticky_footer.classList.add('unscrolled-footer')
			}
			last_scroll_top = scrollTop <= 0 ? 0 : scrollTop; // For Mobile or negative scrolling
		}
	})

	let phone_input = document.querySelector('.phone-input');
	phone_input.addEventListener('keyup', (e) => {
		// KEY CODE: 189 - Hyphen
		if (e.keyCode == 189) {
			let phone_array = phone_input.value.split("")
			phone_array.pop();
			phone_input.value = phone_array.join("")
		}
		// KEY CODE: 8 - Backspace
		if (e.keyCode != 8 && e.keyCode != 189) {
			handlePhoneInput()
		}
	});

	let form_errors = document.querySelectorAll('.form-error');
	form_errors.forEach(error => {
		error.parentElement.querySelector('label').style.color = '#AC1111'
		error.previousElementSibling.style.borderBottomColor = '#AC1111'
	})

	let form_inputs = document.querySelectorAll('.form-input');
	form_inputs.forEach(input => {
		input.addEventListener('click', () => {
			input.style.borderBottomColor = '#C1C1C1'
			input.style.transition = 'all 0.25s ease';
			input.previousElementSibling.style.color = '#232B2D'
			input.previousElementSibling.style.transition = 'all 0.25s ease';
			try {
				input.nextElementSibling.style.opacity = 0;
				input.nextElementSibling.style.transition = 'all 0.25s ease';
				setTimeout(() => {
					input.nextElementSibling.style.display = 'none'
				}, 250)
			} catch {}
		})
	})

	let form_success = document.querySelector('.form-success')
	if (form_success != null) {
		form_success.style.opacity = 0
		form_success.style.display = 'flex'
		setTimeout(() => {
			form_success.style.opacity = 1
			form_success.style.transition = '0.5s opacity ease'
		}, 100)
	}

	let recaptcha = document.querySelector('.grecaptcha-badge');
	recaptcha.style.bottom = '100px';
	recaptcha.style.zIndex = 4;
	recaptcha.style.transition = 'bottom 1s ease-out';

	// Functions
	function openMobileMenu() {
		mobile_menu.style.transform = 'translateY(0%)'
		mobile_menu.style.opacity = 1;
		mobile_menu.style.zIndex = 20;
		mobile_menu.style.transition = 'opacity 0.25s ease'
		body.style.overflowY = 'hidden';
		sticky_footer.style.bottom = '-100px';
		sticky_footer.style.transition = 'bottom 0.25s ease'
	}

	function closeMobileMenu() {
		setTimeout(() => {
			mobile_menu.style.transform = 'translateY(-100%)'
		}, 250)
		mobile_menu.style.opacity = 0;
		mobile_menu.style.transition = 'opacity 0.25s ease'
		mobile_menu.style.zIndex = -10;
		body.style.overflowY = 'auto';
		sticky_footer.style.bottom = '0px';
		sticky_footer.style.transition = 'bottom 0.25s ease'
	}

	function handlePhoneInput() {
		let phone_number = phone_input.value

		if (phone_number.length >= 3) {
			checkToAddDashes(phone_number);
		}
	}

	function checkToAddDashes(phone_number) {
		let phone_array = phone_number.split("");

		if (phone_array.length == 4) {
			phone_array.splice(3, 0, '-');
			phone_input.value = phone_array.join("");
		} else if (phone_array.length == 8) {
			phone_array.splice(7, 0, '-');
			phone_input.value = phone_array.join("");
		}
	}
}
